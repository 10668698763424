import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import LogoGradient from '../images/tmtg_gradient_old.svg'

const navigation = [
  { name: 'Home', to: '/', type: 'gatsby' },
  { name: 'Our Mission', to: '/#mission', type: 'anchor' },
  { name: 'Press', to: 'https://ir.tmtgcorp.com/news-events/press-releases/', type: 'external' },
  { name: 'Investors', to: 'https://ir.tmtgcorp.com/', type: 'external' },
]

export default function Header() {
  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <img
                    className="h-10"
                    src={LogoGradient}
                    alt=""
                  />
                </Link>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => {
                        switch (item.type) {
                          case "anchor":
                            return (
                              <AnchorLink className="text-base font-medium text-gray-500 hover:text-gray-900" to={item.to} title={item.name} stripHash />
                            )
                          case  "gatsby":
                            return (
                              <Link
                                to={item.to}
                                className='text-base font-medium text-gray-500 hover:text-gray-900'>
                                {item.name}
                              </Link>
                            )
                          case  "external":
                            return (
                              <a href={item.to} className="text-base font-medium text-gray-500 hover:text-gray-900">{item.name}</a>
                            )
                        }
                      })}
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => {
                switch (item.type) {
                  case "anchor":
                    return (
                      <AnchorLink className="text-gray-600 hover:bg-blue-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-500 ease-in-out" to={item.to} title={item.name} stripHash />
                    )
                  case  "gatsby":
                    return (
                      <Link
                        to={item.to}
                        className="text-gray-600 hover:bg-blue-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-500 ease-in-out">
                        {item.name}
                      </Link>
                    )
                  case  "external":
                    return (
                      <a href={item.to} className="text-gray-600 hover:bg-blue-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-500 ease-in-out">{item.name}</a>
                    )
                }
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
