import { Link } from 'gatsby'
import React from 'react'
import LogoDark from '../images/tmtg_gradient_old.svg'

function Footer() {
  return (
    <footer className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="flex justify-center my-8">
          <Link to="/" className="">
            <img
              className="h-10 opacity-20 transition ease-in-out  hover:opacity-60"
              src={LogoDark}
              alt=""
            />
          </Link>
        </div>
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          <div className='px-5 py-2'>
            <Link
              to='https://careers.tmtgcorp.com'
              className='text-base text-gray-500 hover:text-gray-900'>
              Careers
            </Link>
          </div>
          <div className='px-5 py-2'>
            <Link
              to='/contact'
              className='text-base text-gray-500 hover:text-gray-900'>
              Contact
            </Link>
          </div>
          <div className='px-5 py-2'>
            <Link
              to='/privacy-policy'
              className='text-base text-gray-500 hover:text-gray-900'>
              Privacy Policy
            </Link>
          </div>
          <div className='px-5 py-2'>
            <Link
              to='/terms-of-service'
              className='text-base text-gray-500 hover:text-gray-900'>
              Terms of Service
            </Link>
          </div>
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">&copy;{` `}2023 TMTG. All Rights Reserved.</p>
      </div>
      </footer>
  )
}

export default Footer
